import React from "react";
import loadable from "@loadable/component";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS,
  QUICK_BOOK,
} from "./constants";

const PrivacyPolicy = loadable(() =>
  import("./components/public_pages/privacy_policy")
);
const TermsAndConditions = loadable(() =>
  import("./components/public_pages/terms_and_conditions")
);
const QuickBook = loadable(() =>
  import("./components/public_pages/quick_book")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">loading</div>
  </div>
);

function App() {
  return (
    <div>
      <Router>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path={PRIVACY_POLICY_ROUTE}
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path={TERMS_AND_CONDITIONS}
              element={<TermsAndConditions />}
            />
            <Route exact path={QUICK_BOOK} element={<QuickBook />} />
          </Routes>
        </React.Suspense>
      </Router>
    </div>
  );
}
export default App;
